
































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Divider from '@/modules/settings/components/divider/Divider.vue';
import DotsIcon from '@/assets/images/dots.svg';
import List from '@/modules/settings/components/list/List.vue';
import ListItem from '@/modules/settings/components/list/list-item/ListItem.vue';

import PopupContext from '@/modules/settings/views/directions/components/Editor.vue';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import Button from '@/components/ui/button';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import RolesModule from '@/store/modules/roles/RolesModule';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import Input from '@/components/ui/input/Input.vue';
import Icon from '@/components/ui/icon/Icon.vue';
import ContextMenuToggle from '@/components/context-menu/items/ContextMenuToggle.vue';
import Notify from '@/services/helpers/notify';
import { Role, Specialisations, SubRole } from '@/typings/domain';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import { forEach } from '@tiptap/core/dist/packages/core/src/commands';

@Component({
  name: 'RolesAndRights',
  components: {
    Button,
    Dropdown,
    PopupContext,
    Icon,
    List,
    ListItem,
    DeleteContext,
    Divider,
    ContextMenuSpawner,
    ContextMenuHeader,
    ContextMenuLink,
    Input,
    ContextMenuToggle,
  },
})
export default class RolesAndRights extends Vue {
  public dotsIcon = DotsIcon;
  public popupStatus = false;
  public deleteRoleContextVisible = false;
  public rolePopupStatus = false;
  public deleteContextVisible = false;
  
  public editRole = {} as Role;
  public deleteRole = {} as Role;
  public stopPaginate = false;
  public pageLoaded = false;
  public editSpecialisation = {} as SubRole;
  public deleteSpecialisation = {} as SubRole;
  public roleOverIndex = -1;
  public paginationInited = false;

  async mounted()
  {
    if(!this.roles.length)
    {
      await RolesModule.fetchRoles()
    }

    this.initPagiantion()
  }


  initPagiantion()
  {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio <= 0) return;
        this.paginateRoles()
      });
      intersectionObserver.observe(this.$refs.rolesEnd as any);
  }
  get rolesPage()
  {
    return RolesModule.paginationPage;
  }
  
  paginateRoles()
  { 
    if(!RolesModule.endPaginateRoles)
    {
      RolesModule.fetchRoles({page: this.rolesPage})
      // RolesModule.setPaginationPage(this.rolesPage)
    }
  }
  addSpecialization(roleId: number, value: {name: string}) {
    RolesModule.createSpecialization({name: value.name, parentId: roleId}).then(() => 
    {
      Notify.success('Специализация создана')
    });
  }
  updateSpecialisation(value: {name?: string, is_admin?: boolean})
  { 
    SpecializationsModule.updateSpecialisations({name: value.name, specsId: this.editSpecialisation.id, is_admin: value.is_admin}).then(() => 
    {
      Notify.success('Обновлено')
      this.popupStatus = false;
    })
  }
  setEditSpecialisation(specialisation: SubRole)
  {
    this.editSpecialisation = specialisation;
    this.popupStatus = true;
  }
  updateSpecialisationRight(specialisation: SubRole)
  {
    specialisation.is_admin = !specialisation.is_admin
    this.editSpecialisation = specialisation;

    this.updateSpecialisation({is_admin: specialisation.is_admin})
  }
  updateRoleRights(role: Role)
  { 
    role.is_admin = !role.is_admin
    
    RolesModule.editRole({id: role.id, is_admin: role.is_admin}).then(() => 
    {
      if(!role.is_admin)
      {
        const id = RolesModule.roles.findIndex((el) => el.id === role.id)
        if(id >= 0)
        {
          RolesModule.roles[id].specialisations = RolesModule.roles[id].specialisations.map((el) => 
          {
            if(!el.is_admin)
            {
              return {
                ...el,
                is_admin: false,
              }
            }
            else
            {
              return {
                ...el,
                is_admin: true,
              }
            }
          })
        } 
      }
      this.rolePopupStatus = false;
      Notify.success('Роль обновлена')
    })    
  }
  
  updateRole(role: Role)
  { 
    
    RolesModule.editRole({name: role.name, id: role.id}).then(() => 
    {
      this.rolePopupStatus = false;
      Notify.success('Роль обновлена')
    })    
  }
  setUpdatedRole(role: Role)
  {
    this.rolePopupStatus = true;
    this.editRole = role;
  }
  setDeleteRole(role: Role)
  {
    this.deleteRoleContextVisible = true
    this.deleteRole = role;
  }
  setDeleteSpecialisation(specialisation: SubRole)
  {
    this.deleteSpecialisation = specialisation;
    this.deleteContextVisible = true;
  }
  deleteSpecialization(specializationId: number): void {
    RolesModule.deleteSubRole(specializationId).then(() => 
    {
      Notify.success('Специализация удалена')
      this.deleteContextVisible = false;
    });
  }

  deleteRoleAction(id: number) {
    RolesModule.deleteRole(id).then(() => 
    {
      Notify.success('Роль удалена')
      this.deleteRoleContextVisible = false;
    });
  }

  get roles() {
    return RolesModule.actualRoles;
  }
  get pages()
  {
    return RolesModule.paginationPage
  }
}
